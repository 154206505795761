import React, { useContext, useState, useEffect } from "react";


import Footer from "../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../Components/HomeComponents/Navbar/Navbar";
import Context from "../../Context/Context";
import wallet_i from "../../Assets/wallet_login_register.png";
import { BsEyeFill } from "react-icons/bs";
/* import login from "../../../Assets/login.svg"; */
import login from "../../Assets/login.svg";
import styles from "./OTP.module.scss";
import { sendRequest, vpassword } from "../../Helper/helper";
import validator from "validator";
import swal from "sweetalert";
import jwt_decode from "jwt-decode";
import ReCaptcha from "../../Components/ReCaptcha/ReCaptcha";
import { Link, useNavigate } from "react-router-dom";
import { MdSecurity } from "react-icons/md";
import { BsPersonCheckFill } from "react-icons/bs"
const Otp = ({ _redirect = "exchange",userposition }) => {
  //console.log("location.state?.from",userposition);
  const [sp, setSp] = useState(false);
  const [_alert, set_alert] = useState({});
  const [submitBtn, setSubmitBtn] = useState(false);
  const [lEmail, setLEmail] = useState(false);
  const [lPassword, setLPasssword] = useState(false);
  const [lCode, setLCode] = useState(false);
  const [resetCaptcha, setResetCaptcha] = useState(false);
  const [twoFaStatus, setTwoFaStatus] = useState(false);
  const [resendLink, setResendLink] = useState(false);
  const [resendLinkEmail, setResendLinkEmail] = useState({});
  const [captcha, setCaptcha] = useState();
  const [validate, setValidate] = useState({
    email: "",
    password: "",
  });
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { states } = useContext(Context);
  const spHandler = () => (sp ? setSp(false) : setSp(true));

  const alertHandler = (key, val) => {
    let change = _alert;
    change[key] = val;
    set_alert({ ...change });
  };

  const validateHandler = (key, val) => {
    let change = validate;
    change[key] = val;
    setValidate({ ...change });
  };

  const getData = (key, val) => {
    let change = formData;
    change[key] = val;
    setFormData({ ...change });
    set_alert({});
    if (key === "email") {
      validateHandler(key, validator.isEmail(val));
    } else if (key === "password") {
      validateHandler(key, vpassword(val));
    }
  };

  const navigate = useNavigate();
  const resendLinkHandler = async ()=>{
  const res = await sendRequest(
    {
      email: formData.email,
    },
    "resend-verification-email",
    "post"
  );
  if (res && res.status === 200) {
    setResendLink(false);
  }
  swal(res.message);
}
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate.email && formData.email && formData.password) {
      setLEmail(formData.email)
      setLPasssword(formData.password)
      setSubmitBtn(true);
      const res = await sendRequest(
        {
          email: formData.email,
          password: formData.password,
          "g-recaptcha-response": captcha, 
        },
        "login",
        "post"
      );
      try {
        res && setSubmitBtn(false);
        setResetCaptcha(true);
       
        if (res.status === 200) {
          const decoded = jwt_decode(res.data);
        //  console.log("decoded",decoded);
         localStorage.setItem("hideZero", decoded.hideZeroStatus);
         localStorage.setItem("st", decoded.st);
         document.querySelector("form").reset();
         sessionStorage.setItem("user", JSON.stringify(res.data));
          localStorage.removeItem("Pages");
          
          if (userposition.previous && userposition.previous === '/btrl-game') {
            navigate('/btrl-game'); // Set the desired page as the redirect target
           } else if (userposition.previous && userposition.previous === '/crorepati-lucky-draw') {
            navigate('/crorepati-lucky-draw'); // Set the desired page as the redirect target
          } else {
            navigate(`/${_redirect}`); // Set the default dashboard page as the redirect target
          }
        
        } else if (res.status === 402) {
          swal(res.message);
        } else if (res.status===405){
          swal(res.message);
          setResendLink(true);
          setResendLinkEmail({"email":formData.email});
        }else if(res.status===406){
          setTwoFaStatus(true);
        }else {
          swal(res.message);
        }
      } catch (err) {
        if (err) {
          //swal(res.message, res.data);
          swal(res.message?res.message:"Something went wrong. Try again.");
        }
      }
    }else{
      if(!formData.email && !validate.email){
        alertHandler("email", "Please enter email !");
      }
      if(!formData.password){
        alertHandler("password", "Please enter password !");
      }
    }
  };
const tFAVerifyHandler = async()=>{
  if(lCode){
    const res = await sendRequest(
      {
        email: lEmail,
        password: lPassword,
        "code": lCode, 
      },
      "verify-code",
      "post"
    );
    try {
      if (res.status === 200) {
        const decoded = jwt_decode(res.data);
      localStorage.setItem("hideZero", decoded.hideZeroStatus);
      document.querySelector("form").reset();
      sessionStorage.setItem("user", JSON.stringify(res.data));
        localStorage.removeItem("Pages");
        
        if (userposition.previous && userposition.previous === '/btrl-game') {
            navigate('/btrl-game'); // Set the desired page as the redirect target
           }else if (userposition.previous && userposition.previous === '/crorepati-lucky-draw') {
          navigate('/crorepati-lucky-draw'); // Set the desired page as the redirect target
        } else {
          navigate(`/${_redirect}`); // Set the default dashboard page as the redirect target
        }
      
      }
      else{
        swal(res.message);
      }
    } catch (err) {
      if (err) {
        //swal(res.message, res.data);
        swal(res.message?res.message:"Something went wrong. Try again.");
      }
    }

  }
  else{
    swal("Please enter a vaild google authentication code.");
  }
}
  const dmode = states.dmode;

  useEffect(() => {
    // window.scrollTo(0, 0)
  }, []);
  return (
    <div className={dmode ? `${styles.signin_l} signPage ligtMode` : `${styles.signin_l} signPage ligtMode`}>
      <Navbar />

      <div className='locktag text-center'><i className="fa-solid fa-lock"></i>  URL Verification: <b><span className='greenText'>https://</span>www.btrlexchange.com</b></div>
      <div className={`${styles._signin}  container padSec padSecimporant`}>
        <div className="row align-items-center justify-content-center">
          <div className="col-sm-12 col-md-6 col-xl-6 my_col_mobile">
              
              <form onSubmit={handleSubmit} className="flex-start column">

                <h2 className="text-start titleh2">OTP Verification</h2>
                <h5>Verify Your Identity Securely</h5>
                <div class="form-group w-100">
                  <label>One Time Password</label>
                  <input
                    type="number"
                    placeholder="Ex: 123456"
                    className='w-100'
                  />
                <div className={styles.warning_note}>
                </div>
                  <p>
                    {validate.email === false &&
                      formData.email.length > 0 &&
                      "Please fill your valid email id"}
                  </p>
                  <p>{_alert.email}</p>
                </div>
                <ReCaptcha
                  checkCaptcha={setCaptcha}
                  reset={resetCaptcha}
                  setReset={setResetCaptcha}
                />
                <div className="bntandfor d-flex gap-3">
                <button
                  type="submit"
                  className={`${styles.signin_btn} btn`}
                >
                  {submitBtn ? (
                    <i className="fa-solid fa-spinner fa-spin"></i>
                  ) : (
                    "Submit "
                  )}
                </button>
              </div>
              </form>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Otp;
