import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from "react-helmet-async";
import App from './App';
import './Global/Universal.scss'
import './darkmode.css'
import './responsive.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HelmetProvider>
    <App />
</HelmetProvider>
);

