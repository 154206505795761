import React from "react";
import styles from "./News.module.scss";

const News = () => {
  return (
    <section className={styles.news_l}>
    <iframe
      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbitcoinregular&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=119894037854893"
      width="340"
      height="500"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    ></iframe>
<div id="fb-root"></div>
  </section>
  );
};

export default News;
