import React, { useContext, useState, useEffect } from "react";
import Footer from "../../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../../Components/HomeComponents/Navbar/Navbar";
import Context from "../../../Context/Context";
import wallet_i from "../../../Assets/wallet_login_register.png";
import styles from "./NomineeInfo.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { 
  allLetter, 
  authRequest, 
  authRequestNb,
  sendFileRequest,
  refreshToken,
  vpancard,
  alphanumeric,
  vIndiaphone,
  validateEmail,
  validateAadhar,
  validatePassport,
  validateDrivingLicence,
  validateVoterID
 } from "../../../Helper/helper";
import swal from "sweetalert";

const NomineeInfo = () => {
  const [checkS, setCheckS] = useState();
  const [data, setData] = useState();
  const { states } = useContext(Context);
  const [formData, setFormData] = useState({
    full_name: "",
    relation: "",
    email: "",
    phone_number: "",
    verification_document: "",
    document_no: "",
    dob: "",
    status: "", // Default status
  });
  const [profileImage, setProfileImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState({});
  const [allClear, setAllClear] = useState(false);
  const dmode = states.dmode;
  const formDataSet = (key, val) => {
    let change = formData;
    change[key] = val;
    setFormData({ ...change });
  };
  const errorMessageSet = (key, val) => {
    let change = errorMessage;
    change[key] = val;
    setErrorMessage({ ...change });
  };
  const convertDate = (dob) => {
    const date = new Date(dob);
    const formattedDate = date.toLocaleDateString('en-CA');  
    return formattedDate;
  };
  const convertFullDate = (inputDate) => {
    const dateObj = new Date(inputDate); 
    dateObj.setHours(0, 0, 0, 0);
    const formatted = dateObj.toString(); 
    return formatted;
  };

  const formFillCheck = (key, value) => {
      let change = formData;
        change[key] = value;
        setFormData({ ...change });
    
        if (!!value) {
          errorMessageSet(key, "");
          if (key === "full_name") {
            !allLetter(value) && errorMessageSet(key, "Please enter valid name");
            allLetter(value) && errorMessageSet(key, "");
          }
          if (key === "relation") {
            formData.relation !== value &&
            errorMessageSet(key, "Please select valid relation");
            formData.relation === value && errorMessageSet(key, "");
          }
         
          if (key === "email") {
            
            
            !validateEmail(value) && errorMessageSet(key, "Please enter valid email");
            validateEmail(value) && errorMessageSet(key, "");
          }
          if (key === "phone_number") {
            
            
             !vIndiaphone(value) && errorMessageSet(key, "Please enter valid phone number");
             vIndiaphone(value) && errorMessageSet(key, "");
          }
          if (key === "verfication_document") {
            formData.verfication_document !== value &&
            errorMessageSet(key, "Please select verification document");
            formData.verfication_document === value && errorMessageSet(key, "");
          }
          if (key === "document_no") {
            if(formData.verfication_document==="aadhar"){
              !validateAadhar(value) && errorMessageSet(key,"Please enter valid document no");
              validateAadhar(value) && errorMessageSet(key,"");
            }else if(formData.verfication_document==="passport"){
              !validatePassport(value) && errorMessageSet(key,"Please enter valid document no");
              validatePassport(value) && errorMessageSet(key,"");
            }else if(formData.verfication_document==="license"){
              !validateDrivingLicence(value) && errorMessageSet(key,"Please enter valid document no");
              validateDrivingLicence(value) && errorMessageSet(key,"");
            }else if(formData.verfication_document==="voterid"){
              !validateVoterID(value) && errorMessageSet(key,"Please enter valid document no");
              validateVoterID(value) && errorMessageSet(key,"");
            }
            // formData.document_no !== value &&
            // errorMessageSet(key, "Please enter valid document no");
            // formData.document_no === value && errorMessageSet(key, "");
          }
          if (key === "dob") {
            formData.dob !== value &&
            errorMessageSet(key, "Please enter valid dob");
            formData.dob === value && errorMessageSet(key, "");
          }         
        } else {
          errorMessageSet(key, "");
        }
    // (formData.name === "" || formData.name === undefined) &&
    //   errorMessageSet("name", "Please enter your name");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    if (
      formData.full_name &&
      !errorMessage.full_name &&
      formData.phone_number &&
      !errorMessage.phone_number &&
      formData.verfication_document &&
      !errorMessage.verfication_document &&
      formData.document_no &&
      !errorMessage.document_no &&
      formData.dob &&
      !errorMessage.dob &&
      formData.relation &&
      !errorMessage.relation &&
      formData.email &&
      !errorMessage.email 
    ) {
        const res = await sendFileRequest(
          {
            full_name: formData.full_name,
            phone_number: formData.phone_number,
            verfication_document: formData.verfication_document,
            document_no: formData.document_no,
            dob: convertDate(formData.dob),
            relation: formData.relation,
            email: formData.email,
            country: formData.country,
          },
          "submit-nominee-details"
        );
       
        const _swal = await swal(res.message);
        _swal && (await autoGet());
      }else {
        if (!formData.full_name) {
          errorMessageSet("full_name", "Please enter full name");
        }
        if (!formData.phone_number) {
          errorMessageSet("phone_number", "Please enter phone number");
        }
        if (!formData.verfication_document) {
          errorMessageSet("verfication_document", "Please select document type");
        }
        if (!formData.document_no) {
          errorMessageSet("document_no", "Please document no");
        }
        if (!formData.dob) {
          errorMessageSet("dob", "Please enter dob");
        }
        if (!formData.relation) {
          errorMessageSet("relation", "Please enter relation");
        }
        if (!formData.email) {
          errorMessageSet("email", "Please enter email");
        }
       
      }
    
  };

  const autoGet = async () => {
    const res = await authRequestNb("get-nominee-details");
    
      setData(res.data);
      setCheckS(res.data.status);
      formDataSet("full_name", res.data.full_name);
      formDataSet("phone_number", res.data.phone_number);
      formDataSet("verfication_document", res.data.verfication_document);
      formDataSet("document_no", res.data.document_no);
      formDataSet("dob", res.data.dob ? convertFullDate(res.data.dob) : "");
      formDataSet("relation", res.data.relation);
      formDataSet("email", res.data.email);
      formDataSet("status", res.data.status);
      refreshToken(res.refresh_token);
  };

  useEffect(() => {
    (async () => {
      await autoGet();
    })();
  }, []);


  
  return (
    <div className={dmode ? `${styles.pers_d} ligtModeExchange signPage`: `${styles.pers_l} ligtModeExchange signPage`}>
      <Navbar />
      <div className={`${styles._pers} container controlminheight`}>
        <div className="row w-100 justify-content-center">
          <div className="col-sm-9">
            <form onSubmit={handleSubmit} className="flex-start column">
              <div className="text-center w-100">
                <h2 className="text-center">Add Nominee</h2>
                <p className="mb-0">Please provide details of the Nominee you would like to add.</p>
              </div>
              
              <div className="w-100">
                <div className="row">
                  <div className="mt-4">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group w-100 ">
                          <label className="text-start d-block">Full Name</label>
                            <input
                              type="text"
                              readOnly={
                                formData &&
                                (formData.status  !== "Submitted")
                                  ? false
                                  : true
                              }
                              placeholder="Full Name"
                              value={formData.full_name}
                              onChange={(e) =>
                                formFillCheck("full_name", e.target.value.toUpperCase())
                              }
                            />
                              {errorMessage.full_name && (
                                <p className={styles.warning_note}>{errorMessage.full_name}</p>
                              )}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group w-100 ">
                          <label className="text-start d-block">Relation</label>
                            <select 
                                value={formData.relation}
                                defaultValue={formData.relation}
                                onChange={(e) =>
                                  formFillCheck("relation", e.target.value)
                                }
                                disabled={!(formData && (formData.status  !== "Submitted"))}
                              >
                                <option value=''>Select Relation</option>
                                <option value='Father'>Father</option>
                                <option value='Mother'>Mother</option>
                                <option value='Spouse'>Spouse</option>
                                <option value='Other'>Others</option>
                            </select>
                              {errorMessage.relation && (
                                <p className={styles.warning_note}>{errorMessage.relation}</p>
                              )}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group w-100 ">
                          <label className="text-start d-block">Email</label>
                            <input
                              type="text"
                              readOnly={
                                formData &&
                                (formData.status !== "Submitted")
                                  ? false
                                  : true
                              }
                              placeholder="Email"
                              value={formData.email}
                              onChange={(e) =>
                                formFillCheck("email", e.target.value)
                              }
                            />
                            {errorMessage.email && (
                                <p className={styles.warning_note}>{errorMessage.email}</p>
                              )}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group w-100 ">
                          <label className="text-start d-block">Phone Number (e.g. +919876543210)</label>
                            <input
                              type="text"
                              readOnly={
                                formData &&
                                (formData.status  !== "Submitted")
                                  ? false
                                  : true
                              }
                              placeholder="Phone Number (e.g. +919876543210)"
                              value={formData.phone_number}
                              onChange={(e) =>
                                formFillCheck("phone_number", e.target.value)
                              }
                            />
                             {errorMessage.phone_number && (
                                <p className={styles.warning_note}>{errorMessage.phone_number}</p>
                              )}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group w-100 ">
                          <label className="text-start d-block">Verification Document</label>

                            <select 
                                value={formData.verfication_document}
                                onChange={(e) =>
                                  formFillCheck("verfication_document", e.target.value)
                                }
                                defaultValue={formData.verfication_document}
                                disabled={!(formData && (formData.status  !== "Submitted"))}
                              >
                                <option value="">Select Document</option>
                                <option value='aadhar'>Aadhar Card</option>
                                <option value='passport'>Passport </option>
                                <option value='license'>Driving licence </option>
                                <option value='voterid'>Voter ID</option>
                            </select>
                            {errorMessage.verfication_document && (
                                <p className={styles.warning_note}>{errorMessage.verfication_document}</p>
                              )}
                        </div>
                      </div>
                      <div className="col-sm-4 ">
                        <div className="form-group w-100 ">
                          <label className="text-start d-block">Document Number</label>
                            <input
                              type="text"
                              readOnly={
                                formData &&
                                (formData.status  !== "Submitted")
                                  ? false
                                  : true
                              }
                              placeholder="Document Number"
                              value={formData.document_no}
                              onChange={(e) =>
                                formFillCheck("document_no", e.target.value)
                              }
                            />
                            {errorMessage.document_no && (
                                <p className={styles.warning_note}>{errorMessage.document_no}</p>
                              )}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group w-100 ">
                          <label className="text-start d-block">Date of Birth</label>
                          <DatePicker
                            id="dob"
                            selected={formData?.dob ? new Date(formData.dob) : null}
                            onChange={(date) => formFillCheck("dob", date)}
                            dateFormat="yyyy-MM-dd"
                            showYearDropdown
                            yearDropdownItemNumber={100}
                            scrollableYearDropdown
                            placeholderText="Select your DOB"
                            maxDate={new Date()} // Prevents selecting future dates

                            readOnly={
                              formData &&
                              (formData.status  !== "Submitted")
                                ? false
                                : true
                            }
                          />
                        
                         
                            {errorMessage.dob && (
                                <><br/> <p className={styles.warning_note}>{errorMessage.dob}</p></>
                              )}
                         
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
              {formData &&
                (formData.status !== "Submitted") && (
                  <button type="submit" className={`${styles.bankdetials_btn} btn`}>
                    Submit
                  </button>
                )
              }
            </form>
          </div>
        </div>

        
      </div>

      <Footer />
    </div>
  );
};

export default NomineeInfo;
