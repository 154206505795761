/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import Context from "./Context/Context";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Home from "./Pages/Home/Home";
import Exchange from "./Pages/Exchange/Exchange";
import Register from "./Pages/Register/Register";
import ContactUs from "./Pages/ContactUs/ContactUs";
import News from "./Pages/News/News";
import Fees from "./Pages/Fees/Fees";
import UnderMaintenance from "./Pages/InfoPages/UnderMaintenance/UnderMaintenance";
import SignIn from "./Pages/SignIn/SignIn";
import OTP from "./Pages/OTP/OTP";
import CompanyInfo from "./Pages/CompanyInfo/CompanyInfo";
import Coininfo from "./Pages/Coininfo/Coininfo";
import NoteFound from "./Pages/InfoPages/NotFound/NotFound";
import Approved from "./Pages/InfoPages/Approved/Approved";
import BankDetails from "./Pages/Secure/BankDetails/BankDetails";
import Balance from "./Pages/Secure/Balance/Balance";
import Refferal from "./Pages/Secure/Refferal/Refferal";
import PensionPage from "./Pages/Secure/PensionRank/PensionPage";
import History from "./Pages/Secure/History/History";
import Stack from "./Pages/Secure/Stack/Stack";
import ChangePassword from "./Pages/ChangePassword/ChangePassword";
import ForgetPassword from "./Pages/ForgetPassword/ForgetPassword";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import VerifyEmail from "./Pages/VerifyEmail/VerifyEmail";
import NewsDetails from "./Pages/NewsDetails/NewsDetails";

import About from "./Pages/Information/About";
import Refundpolicy from "./Pages/Information/Refundpolicy";
import Termscondition from "./Pages/Information/Termscondition";
import Privacypolicy from "./Pages/Information/Privacypolicy";
import Riskstatement from "./Pages/Information/Riskstatement";
import Security from "./Pages/Secure/Security/Security";
import Rank from "./Pages/PensionRank/Rank";
import Myrank from "./Pages/Myrank/index";
import PersonalInfo from "./Pages/Secure/PersonalInfo/PersonalInfo";
import Nominee from "./Pages/Secure/Nominee/NomineeInfo";
import SignupBonus from "./Pages/Secure/SignupBonus/SignupBonus";
//Latestpages//

import Home from "./latestpages/home";
//Latestpages End//
//import { sendGetRequest, usernoCheck, webSocketUrl,sendRequestTest } from "./Helper/helper";
import { sendGetRequest, usernoCheck, webSocketUrl } from "./Helper/helper";
import Manage from "./Pages/Manage/Manage";
import Lottery from "./Pages/Lottery/Lottery";
import LotteryWinner from "./Pages/Lottery/Lottery-winner";
import Game from "./Pages/Game/Game";
import { Helmet } from "react-helmet-async";
let check = 0;
const balanceObj = {};
let _tradeHistory = {};
let checkTH=0;
const path = window.location.pathname; 
function App() {
  const extheme= localStorage.getItem("theme");
  //console.log("extheme=>",extheme);
  const [states, updateChanges] = useState({
    dmode: JSON.parse(localStorage.getItem("dmode")),
    swidth: window.innerWidth,
    stokenbtn: false,
    buyTrade: true,
    isLogin: JSON.parse(sessionStorage.getItem("isLogin")),
    extheme: localStorage.getItem("theme"),
  });
  function changeState(a, b, c, d, e, f) {
    updateChanges({
      dmode: a,
      swidth: b,
      stokenbtn: c,
      buyTrade: d,
      isLogin: e,
      extheme: f,
    });
  }
  
  const [orderByWs, setOrderByWs] = useState();
  const [userposition, setUserposition] = useState({"current":"","previous":""});
  const [marketPair, setMarkerPair] = useState();
  const [globalRes, setGlobalRes] = useState();
  const [balance, setBalance] = useState([0]);
  const [lotteryStatus, setLotteryStatus] = useState(0);
  const [gameStatus, setGameStatus] = useState(0);
  const [marketHistory, setMarketHistory] = useState();
  const [tradeHistory, setTradeHistory] = useState([""]);
  const [openOrder, setOpenOrder] = useState();
  const [openOrderTotal, setOpenOrderTotal] = useState(0);
  const [loadOpenOrder,setLoadOpenOrder] = useState(0);
  const [cancelOrder, setCancelOrder] = useState();
  const [socketdata, setSocketdata] = useState("");
  const [ooData, setOoData] = useState([]);
  const [exop, setExop] = useState([]);

  const [ticker, setTicker] = useState();
  const [newticker, setnewTicker] = useState();
  const [metaStatus,setMetaStatus]=useState(false);
  const [metaTitle, setMetaTitle] = useState("BTRL Exchange - Cryptocurrency Exchange | Bitcoin Exchange | Altcoin Exchange");
  const [metaDescription, setMetaDescription] = useState("Trade Bitcoin, Ethereum, and 50+ cryptocurrencies securely on India's leading crypto exchange. Buy & sell crypto with INR at the best rates. Fast deposits & withdrawals!");
  const [metaContent, setMetaContent] = useState("Best cryptocurrency,Best crypto Exchange in india ,Crypto exchange,Best crypto Exchange,Crypto trading platform,Best crypto trading platform,Best crypto to buy now,Best crypto app,Best crypto exchange,Trading crypto App,Btrl Exchange Application,Best cryptocurrency to invest in 2025,crypto that will make you rich in 2025,next cryptocurrency to explore 2025,best crypto coin price prediction 2025,best crypto coins for 2025,best crypto investment for 2025,best crypto to buy for 2025,best crypto to hold till 2025,best crypto to invest for 2025,cryptocurrency that will boom in 2025,best trading in india,best trading app in india,best trading platform in india,best trading app india for beginners,best demat account in india,trading app in india,best trading app in india to earn money,best trading app in india 2025,top 10 trading apps in india,best app for trading in india,Top 10 crypto Exchanges in india ,Top 10 crypto Exchanges in world");
  // let websocket;
  const [websocket, setWebsocket] = useState(null);
  const ws = useRef(null);
  const exchangePage = (minimdata) => {
    setExop(minimdata);
  };
  

  const _path = window.location.pathname;
  const segments = _path.split("/").filter(Boolean);
  //console.log("_path",_path);
   //console.log("_path",_path);
   useMemo(() => {
    // console.log("socketdata",socketdata);
       //setOrderByWs()
       //setBalance([0])
      // setMarketHistory()
       setTicker()
      // setCancelOrder()
       setTradeHistory("")
       //setOpenOrder()
       setOpenOrderTotal()
         
   }, [_path,socketdata])

  
  useEffect(()=>{
    if(_path){
      setMetaStatus(false)
      //console.log("_path",_path)
      if(userposition.current!==_path){
        setUserposition({"current":_path,"previous":userposition.current})
      }
    }
  },[_path])


 useEffect(() => {
  
    // websocket = new WebSocket(webSocketUrl);
    setWebsocket(new WebSocket(webSocketUrl));
  
 }, [webSocketUrl])
 

 useEffect(() => {
  if(websocket){
  
    websocket.onopen = () => {
      // console.log('connected');
     }
    
     websocket.onmessage = (event) => {
    // alert("exop=>");
      // console.log("exop=>",exop);
      // const data = JSON.parse(event.data);
      if(event){
       
        try {
          const mys=event?.data ? JSON.parse(event.data):[];
          
           if(Object.keys(mys)[0]==="btrl"){
               
                if(exop!==undefined && exop.market_symbol!==undefined && exop.currency_symbol!==undefined){
                  setSocketdata(event)
                 // if (event.data && JSON.parse(event.data).btrl) {
                   //const data = JSON.parse(event.data).btrl;
                   if (mys.btrl) {
                    //console.log("btrl12=>",mys.btrl);
                    const data = mys.btrl;
                    var tradeData = null;
                    if (data) {
                        if (Object.keys(data)[0] === "trade") {
                          tradeData = data.trade;
                          const order = {
                            market: tradeData.market,
                            currency: tradeData.currency,
                            price: parseFloat(tradeData.price),
                            amount: parseFloat(tradeData.amount),
                            buyTrade: tradeData.type === "Buy" ? true : false,
                            type: tradeData.type,
                            operation:tradeData.operation
                          };
                         
                          if(exop.market_symbol.toUpperCase()===tradeData.market.toUpperCase() && exop.currency_symbol.toUpperCase()===tradeData.currency.toUpperCase()){   
                                                 
                               setOrderByWs(order);
                          }
                        } else if (Object.keys(data)[0] === "balance" && usernoCheck(data.balance.users) ) {
                          //console.log("data.balance=>",data.balance);  
                            balanceObj[data.balance.currency_symbol.toUpperCase()] = data.balance.balance;
                            check++
                         
                        } else if (Object.keys(data)[0] === "market_history") {
                          if(exop.market_symbol.toUpperCase()===data.market_history.market_symbol.toUpperCase() && exop.currency_symbol.toUpperCase()===data.market_history.currency_symbol.toUpperCase()){
                            
                               setMarketHistory(data.market_history);
                              
                            
                          }
                        } else if (Object.keys(data)[0] === "trade_history" && usernoCheck(data.trade_history.users) ) {
                          
                            _tradeHistory = data.trade_history
                              setTradeHistory([checkTH,_tradeHistory]);
                              checkTH++
                          
                        } else if (Object.keys(data)[0] === "open_order" ) {
                          const checkUser=usernoCheck(data.open_order.users);
                        //  console.log("checkUser=>",checkUser);
                          if(checkUser){
                              setOpenOrder(data.open_order);
                          }
                        
                          
                          
                        } else if (Object.keys(data)[0] === "order_cancel") {
                          
                             setCancelOrder(data.order_cancel);
                          
                        } else if (Object.keys(data)[0] === "ticker") {
                            
                                 setTicker(data.ticker);
                           
                        }
                      
                    }
                  }
               }
              
            } 
          } catch (error) {
            error && console.log("evt error from Websocket (out) :-" , error)
            
          }
    
      }
      //console.log("working");
     }
      
    
      // return () => {
      //   websocket.close()
      // }
  }
 }, [websocket,exop])

  // useEffect(() => {
 
  //   if(websocket){
  //   websocket.onopen = () => {
  //     console.log('connected');
  //    }
    
  //    websocket.onmessage = (event) => {
     
  //     if(event){
       
  //       try {
  //         const mys=event?.data ? JSON.parse(event.data):[];
          
  //          if(Object.keys(mys)[0]==="btrl"){
               
  //               if(exop!==undefined && exop.market_symbol!==undefined && exop.currency_symbol!==undefined){
  //                 // setSocketdata(event)
  //                // if (event.data && JSON.parse(event.data).btrl) {
  //                  //const data = JSON.parse(event.data).btrl;
  //                  if (mys.btrl) {
  //                   //console.log("btrl12=>",mys.btrl);
  //                   const data = mys.btrl;
  //                   var tradeData = null;
  //                   if (data) {
  //                       console.log("data.balance=>",data.balance);
  //                       if (Object.keys(data)[0] === "trade") {
  //                         tradeData = data.trade;
  //                         const order = {
  //                           market: tradeData.market,
  //                           currency: tradeData.currency,
  //                           price: parseFloat(tradeData.price),
  //                           amount: parseFloat(tradeData.amount),
  //                           buyTrade: tradeData.type === "Buy" ? true : false,
  //                           type: tradeData.type,
  //                           operation:tradeData.operation
  //                         };
                         
  //                         if(exop.market_symbol.toUpperCase()===tradeData.market.toUpperCase() && exop.currency_symbol.toUpperCase()===tradeData.currency.toUpperCase()){   
                                                 
  //                              setOrderByWs(order);
  //                         }
  //                       } else if (Object.keys(data)[0] === "balance" && usernoCheck(data.balance.users) ) {
                          
  //                           balanceObj[data.balance.currency_symbol.toUpperCase()] = data.balance.balance;
  //                           check++
                         
  //                       } else if (Object.keys(data)[0] === "market_history") {
  //                         if(exop.market_symbol.toUpperCase()===data.market_history.market_symbol.toUpperCase() && exop.currency_symbol.toUpperCase()===data.market_history.currency_symbol.toUpperCase()){
                            
  //                              setMarketHistory(data.market_history);
                              
                            
  //                         }
  //                       } else if (Object.keys(data)[0] === "trade_history" && usernoCheck(data.trade_history.users) ) {
                          
  //                           _tradeHistory = data.trade_history
  //                             setTradeHistory([checkTH,_tradeHistory]);
  //                             checkTH++
                          
  //                       } else if (Object.keys(data)[0] === "open_order" ) {
  //                         const checkUser=usernoCheck(data.open_order.users);
  //                       //  console.log("checkUser=>",checkUser);
  //                         if(checkUser){
  //                             setOpenOrder(data.open_order);
  //                         }
                        
                          
                          
  //                       } else if (Object.keys(data)[0] === "order_cancel") {
                          
  //                            setCancelOrder(data.order_cancel);
                          
  //                       } else if (Object.keys(data)[0] === "ticker") {
                            
  //                                setTicker(data.ticker);
                           
  //                       }
                      
  //                   }
  //                 }
  //              }else{
  //               console.log("exop1=>",exop);
  //              }
              
  //           } 
  //         } catch (error) {
  //           error && console.log("evt error from Websocket (out) :-" , error)
            
  //         }
    
  //     }
  //     //console.log("working");
  //    }
      
  //   //  return () => {
  //   //    websocket.close()
  //   //  }
    
  //   }

  //   }, [exop])

  useEffect(() => {
    if(check){
    setBalance([check,balanceObj]);
    }
  }, [check]);


useEffect(() => {
(async()=>{
  const res = await sendGetRequest("global");
  //const res1 = await sendGetRequest("tdsreport");
  setGlobalRes(res)
  localStorage.setItem("lastUpdated",res.data.updated_date)
})()
}, [])
useEffect(() => {
    // console.log("globalRes => ",globalRes)
    if( globalRes?.data?.meta ){
      const page= segments[0] || "home";
      if(!metaStatus){

        //console.log("page",page)
        const metaData = globalRes?.data?.meta?.[page];
        if(metaData?.title){
          setMetaTitle(metaData.title);
         
        }
        if(metaData?.description){
          setMetaDescription(metaData.description);
        }
        if(metaData?.content){
          setMetaContent(metaData.content);
        }
        setMetaStatus(true);
      }
    }
  
}, [segments,globalRes])


useEffect(() => {
(async()=>{
  //console.log("loadOpenOrder=>",loadOpenOrder);
})()
}, [loadOpenOrder])



  return (
    <>
    
    <Helmet>
    <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaContent} />
   </Helmet>
    <Context.Provider value={{ states, changeState }}>
      <Router>
        <Routes>

          {/* <Route path="/crorepati-lucky-draw" exact element={<Lottery 
          lotteryStatus={lotteryStatus} setLotteryStatus={setLotteryStatus}
          />} /> */}
          {/* <Route path="/btrl-game" exact element={<Game 
          gameStatus={gameStatus} setGameStatus={setGameStatus}
          />} /> */}
          <Route path="/" exact element={<Home />} />
          <Route
            path="/exchange/*"
            exact
            element={
              <Exchange
                ticker={ticker}
                setTicker={setTicker}
                globalRes={globalRes}
                cancelOrder={cancelOrder}
                setCancelOrder={setCancelOrder}
                openOrder={openOrder}
                setOpenOrder={setOpenOrder}
                orderByWs={orderByWs}
                setOrderByWs={setOrderByWs}
                balance={balance}
                setBalance={setBalance}
                tradeHistory={tradeHistory[1]}
                setTradeHistory={setTradeHistory}
                marketHistory={marketHistory}
                setMarketHistory={setMarketHistory}
                //myExchangePage={myExchangePage}
                exchangePage={exchangePage}
                marketPair={marketPair}
                setMarkerPair={setMarkerPair}
                
                openOrderTotal={openOrderTotal}
                setOpenOrderTotal={setOpenOrderTotal}
                loadOpenOrder={loadOpenOrder}
                setLoadOpenOrder={setLoadOpenOrder}
                ooData={ooData}
                setOoData={setOoData}
              />
            }
          />
          <Route path="/contact-us" exact element={<ContactUs />} />
          <Route path="/news" exact element={<News />} />
          <Route path="/news/*" exact element={<NewsDetails />} />
          <Route path="/fees" exact element={<Fees />} />
          <Route path="/coininfo" exact element={<Coininfo />} />
          <Route path="/pension-rank" exact element={<Rank />} />

          {/* *********** Secure ********** */}

          <Route
            path="/pending-balance"
            exact
            element={
              states.isLogin ? (
                <SignupBonus />
              ) : (
                <SignIn _redirect="pending-balance" />
              )
            }
          />
          <Route
            path="/bank-details"
            exact
            element={
              states.isLogin ? (
                <BankDetails />
              ) : (
                <SignIn _redirect="bank-details" />
              )
            }
          />
          <Route
            path="/balance"
            exact
            element={
              states.isLogin ? <Balance globalRes={globalRes} update={balance} /> : <SignIn _redirect="balance" />
            }
          />
          <Route
            path="/referral"
            exact
            element={
              states.isLogin ? <Refferal /> : <SignIn _redirect="referral" />
            }
          />
          <Route
            path="/pension-plan"
            exact
            element={
              states.isLogin ? <PensionPage /> : <SignIn _redirect="pension-plan" />
            }
          />
              <Route path="/id-card" exact 
               element={
                states.isLogin ? <Myrank /> : <SignIn _redirect="id-card" />
              }
              globalRes={globalRes}/>
             
          <Route
            path="/history"
            exact
            element={
              states.isLogin ? <History openOrder={openOrder} tradeHistory={tradeHistory[1]} /> : <SignIn _redirect="history" />
            }
          />
          <Route path='/profile' exact element={states.isLogin ?<PersonalInfo /> : <SignIn _redirect='profile' />} />
          <Route
            path="/stack"
            exact
            element={states.isLogin ? <Stack /> : <SignIn _redirect="stack" />}
          />
          <Route
            path="/security"
            exact
            element={states.isLogin ? <Security /> : <Exchange
                ticker={ticker}
                globalRes={globalRes}
                cancelOrder={cancelOrder}
                openOrder={openOrder}
                orderByWs={orderByWs}
                balance={balance}
                tradeHistory={tradeHistory[1]}
                marketHistory={marketHistory}
              />}
          />
          <Route
            path="/nominee"
            exact
            element={states.isLogin ? <Nominee /> : <Exchange
                ticker={ticker}
                globalRes={globalRes}
                cancelOrder={cancelOrder}
                openOrder={openOrder}
                orderByWs={orderByWs}
                balance={balance}
                tradeHistory={tradeHistory[1]}
                marketHistory={marketHistory}
              />}
          />
          <Route
            path="/change-password"
            exact
            element={states.isLogin ? <ChangePassword /> : <Exchange
                ticker={ticker}
                globalRes={globalRes}
                cancelOrder={cancelOrder}
                openOrder={openOrder}
                orderByWs={orderByWs}
                balance={balance}
                tradeHistory={tradeHistory[1]}
                marketHistory={marketHistory}
              />}
          />

          <Route
            path="/signup"
            exact
            element={!states.isLogin ? <Register /> : <Exchange
                ticker={ticker}
                globalRes={globalRes}
                cancelOrder={cancelOrder}
                openOrder={openOrder}
                orderByWs={orderByWs}
                balance={balance}
                tradeHistory={tradeHistory[1]}
                marketHistory={marketHistory}
              />}
          />
          <Route
            path="/signin"
            exact
            element={!states.isLogin ? <SignIn userposition={userposition}/> : <Exchange
                ticker={ticker}
                globalRes={globalRes}
                cancelOrder={cancelOrder}
                openOrder={openOrder}
                orderByWs={orderByWs}
                balance={balance}
                tradeHistory={tradeHistory[1]}
                marketHistory={marketHistory}
                
              />}
          />
          <Route
            path="/otp"
            exact
            element={!states.isLogin ? <OTP userposition={userposition}/> : <Exchange
                ticker={ticker}
                globalRes={globalRes}
                cancelOrder={cancelOrder}
                openOrder={openOrder}
                orderByWs={orderByWs}
                balance={balance}
                tradeHistory={tradeHistory[1]}
                marketHistory={marketHistory}
                
              />}
          />
          <Route
            path="/company-info"
            exact
            element={<CompanyInfo/> }
          />


          <Route
            path="/manage"
            exact
            element={!states.isLogin ? <Manage /> : <Exchange
                ticker={ticker}
                globalRes={globalRes}
                cancelOrder={cancelOrder}
                openOrder={openOrder}
                orderByWs={orderByWs}
                balance={balance}
                tradeHistory={tradeHistory[1]}
                marketHistory={marketHistory}
              />}
          />
          <Route
            path="/forget-password"
            exact
            element={!states.isLogin ? <ForgetPassword /> : <Exchange
                ticker={ticker}
                globalRes={globalRes}
                cancelOrder={cancelOrder}
                openOrder={openOrder}
                orderByWs={orderByWs}
                balance={balance}
                tradeHistory={tradeHistory[1]}
                marketHistory={marketHistory}
              />}
          />
          <Route
            path="/reset-password/*"
            exact
            element={!states.isLogin ? <ResetPassword /> : <Exchange
                ticker={ticker}
                globalRes={globalRes}
                cancelOrder={cancelOrder}
                openOrder={openOrder}
                orderByWs={orderByWs}
                balance={balance}
                tradeHistory={tradeHistory[1]}
                marketHistory={marketHistory}
              />}
          />
          {/* *********** Secure ********** */}

          <Route
            path="/under-maintenance"
            exact
            element={<UnderMaintenance />}
          />
          <Route path="/*" exact element={<NoteFound />} />
          <Route path="/verify-email/*" exact element={<VerifyEmail />} />
          <Route path="/approved" exact element={<Approved />} />

          <Route path="/about" exact element={<About />} globalRes={globalRes}/>
          <Route path="/refund-policy" exact element={<Refundpolicy />} globalRes={globalRes}/>
          <Route
            path="/terms-and-conditions"
            exact
            element={<Termscondition />}
            globalRes={globalRes}
          />
          <Route path="/privacy-policy" exact element={<Privacypolicy />} globalRes={globalRes}/>
          <Route path="/risk-statement" exact element={<Riskstatement />} globalRes={globalRes}/>
          {/* <Route path="/crorepati-winner" exact element={<LotteryWinner />} globalRes={globalRes}/> */}
        </Routes>
      </Router>
    </Context.Provider>
    </>
  );
}

export default App;
